import {
  AccountsService,
  AppReadyService,
  HttpClientHeadersService,
  JwtService,
  LocalStorageService,
  PrintOutService,
  WindowRefService,
  CommonService,
  LocationService,
  SystemSettingsShareService,
  ParseDateFormatService
} from './services';

import { AuthGuard, NoAuthGuard } from './guards';

import {
  AccountingEntriesService,
  AccountMakerCheckerService,
  AuditTrailService,
  AuthService,
  BondCreateService,
  BondEventsService,
  BondListService,
  BondScheduleService,
  BondService,
  BondUpdateService,
  BorrowingCreateService,
  BorrowingEventsActions,
  BorrowingEventsService,
  BorrowingListService,
  BorrowingProductCreateActions,
  BorrowingProductCreateService,
  BorrowingProductInfoActions,
  BorrowingProductInfoService,
  BorrowingProductListActions,
  BorrowingProductListService,
  BorrowingProductUpdateActions,
  BorrowingProductUpdateService,
  BorrowingScheduleService,
  BorrowingService,
  BorrowingUpdateService,
  BranchCreateActions,
  BranchCreateService,
  BranchInfoActions,
  BranchInfoService,
  BranchListActions,
  BranchListService,
  BranchUpdateActions,
  BranchUpdateService,
  BranchFieldsService,
  BusinessSectorCreateActions,
  BusinessSectorCreateService,
  BusinessSectorListActions,
  BusinessSectorListService,
  BusinessSectorUpdateActions,
  BusinessSectorUpdateService,
  TagCreateActions,
  TagCreateService,
  TagsListActions,
  TagsListService,
  TagUpdateActions,
  TagUpdateService,
  CCRuleCreateService,
  CCRulesInfoService,
  CCRulesListService,
  CCRulesUpdateService,
  ChartOfAccountCreateActions,
  ChartOfAccountCreateService,
  ChartOfAccountsActions,
  ChartOfAccountsService,
  ChartOfAccountUpdateActions,
  ChartOfAccountUpdateService,
  CollateralTypeCreateService,
  CollateralTypeDetailsService,
  CollateralTypeListService,
  CollateralTypeUpdateService,
  CurrencyListService,
  CurrentAccountsListService,
  CurrentAccountTransactionsService,
  CurrentUserService,
  EntryFeeCreateService,
  EntryFeeListService,
  EntryFeeUpdateService,
  ExchangeRateService,
  GlobalPermissionsService,
  HolidayCreateService,
  HolidayListService,
  HolidayUpdateService,
  LoanAppAttachmentListService,
  LoanAppCCActivityListActions,
  LoanAppCCActivityListService,
  LoanAppCollateralCreateService,
  LoanAppCollateralDeleteService,
  LoanAppCollateralService,
  LoanAppCollateralListService,
  LoanAppCollateralUpdateService,
  LoanAppEntryFeesService,
  LoanAppGuarantorCreateService,
  LoanAppGuarantorDeleteService,
  LoanAppGuarantorService,
  LoanAppGuarantorsListService,
  LoanApplicationAttachmentDelService,
  LoanApplicationCommentsService,
  LoanApplicationCreateService,
  LoanApplicationFieldsService,
  LoanApplicationListService,
  LoanApplicationService,
  LoanApplicationUpdateService,
  LoanAppMakerCheckerDisburseService,
  LoanAppScheduleService,
  LoanAppUpdateGuarantorService,
  LoanAttachmentDelService,
  LoanAttachmentListService,
  LoanEventsService,
  LoanInfoService,
  LoanListService,
  LoanMakerCheckerRepaymentService,
  LoanMakerCheckerRollbackService,
  LoanPayeeEventsService,
  LoanPayeeInfoService,
  LoanPayeeUpdateService,
  LoanProductCreateService,
  LoanProductHistoryService,
  LoanProductListService,
  LoanProductMakerCheckerService,
  LoanProductService,
  LoanProductUpdateService,
  LoanPurposeCreateService,
  LoanPurposeListService,
  LoanPurposeUpdateService,
  LoanScheduleService,
  LocationCreateService,
  LocationListService,
  LocationUpdateService,
  MakerCheckerListService,
  OperationCreateService,
  OperationListActions,
  OperationListService,
  OtherFeeCreateService,
  OtherFeeListService,
  OtherFeesActions,
  OtherFeesService,
  OtherFeeUpdateService,
  PayeeCreateService,
  PayeeListService,
  PayeeUpdateService,
  ProfessionCreateService,
  ProfessionListService,
  ProfessionUpdateService,
  ProfileAttachmentDelService,
  ProfileAttachmentListService,
  ProfileChangeActions,
  ProfileChangeService,
  ProfileCreateService,
  ProfileEventsListService,
  ProfileFieldsService,
  ProfileMakerCheckerService,
  ProfilesListService,
  ProfileStateService,
  ProfileUpdateService,
  RelationshipListService,
  RoleCreateService,
  RoleListService,
  RoleMakerCheckerService,
  RoleService,
  RoleUpdateService,
  RouteService,
  SavingEntriesService,
  SavingCreateService,
  SavingListService,
  SavingProfileListService,
  SavingProductCreateService,
  SavingProductListService,
  SavingProductMakerCheckerService,
  SavingProductService,
  SavingProductUpdateService,
  SavingService,
  SavingUpdateService,
  SavingEntriesActions,
  SystemSettingService,
  SystemSettingUpdateService,
  TellerListService,
  TermDepositAccountTransactionsService,
  TermDepositCreateService,
  TermDepositEntriesActions,
  TermDepositEntriesService,
  TermDepositListService,
  TermDepositProfileListService,
  TermDepositProductCreateService,
  TermDepositProductInfoService,
  TermDepositProductListService,
  TermDepositProductMakerCheckerService,
  TermDepositProductUpdateService,
  TermDepositService,
  TermDepositUpdateService,
  TillCreateActions,
  TillCreateService,
  TillInfoActions,
  TillInfoService,
  TillListActions,
  TillListService,
  TillUpdateActions,
  TillUpdateService,
  UserCreateService,
  UserListService,
  UserMakerCheckerService,
  UserService,
  UserUpdateService,
  VaultCreateActions,
  VaultCreateService,
  VaultInfoActions,
  VaultInfoService,
  VaultListActions,
  VaultListService,
  VaultUpdateActions,
  VaultUpdateService,
  TransactionTemplatesCreateService,
  TransactionTemplatesInfoService,
  TransactionTemplatesListService,
  TransactionTemplatesUpdateService,
  PenaltyCreateService,
  PenaltiesService,
  PenaltyUpdateService,
  PaymentMethodCreateService,
  PaymentMethodListService,
  PaymentMethodUpdateService,
  IntegrationWithBankExportService,
  IntegrationWithBankExportFileListService,
  IntegrationWithBankImportFileListService,
  PaymentGatewayService,
  CreditLineService,
  CreditLineCreateService,
  CreditLineUpdateService
} from './store';

import { AccountBalanceService } from './services/profile-accounts.service';
import { LoanAppCustomFieldsService } from './store/loan-application';
import { RouteGuard } from './guards/route-guard.service';


export const CORE_SERVICES = [
  // Services
  AppReadyService,
  LocalStorageService,
  JwtService,
  RouteService,
  // Guards
  AuthGuard,
  RouteGuard,
  NoAuthGuard,
  AuthService,
  AuditTrailService,
  CurrentUserService,
  BusinessSectorListService,
  BusinessSectorCreateService,
  BusinessSectorUpdateService,
  TagsListService,
  TagCreateService,
  TagUpdateService,
  ProfileStateService,
  ProfileMakerCheckerService,
  ProfileCreateService,
  ProfileUpdateService,
  ProfileFieldsService,
  ProfilesListService,
  ProfileChangeService,
  RoleListService,
  RoleService,
  RoleMakerCheckerService,
  UserListService,
  UserService,
  UserMakerCheckerService,
  UserUpdateService,
  UserCreateService,
  RoleUpdateService,
  RoleCreateService,
  LocationListService,
  LocationCreateService,
  LocationUpdateService,
  LoanProductService,
  LoanProductMakerCheckerService,
  LoanProductListService,
  LoanProductCreateService,
  LoanProductHistoryService,
  LoanProductUpdateService,
  LoanPurposeListService,
  LoanPurposeCreateService,
  LoanPurposeUpdateService,
  LoanPayeeInfoService,
  LoanPayeeUpdateService,
  LoanPayeeEventsService,
  PayeeListService,
  PayeeCreateService,
  PayeeUpdateService,
  HolidayListService,
  HolidayCreateService,
  HolidayUpdateService,
  ProfessionListService,
  ProfessionCreateService,
  ProfessionUpdateService,
  ProfileAttachmentListService,
  ProfileAttachmentDelService,
  CollateralTypeListService,
  CollateralTypeCreateService,
  CollateralTypeUpdateService,
  CollateralTypeDetailsService,
  EntryFeeListService,
  EntryFeeCreateService,
  EntryFeeUpdateService,
  LoanApplicationService,
  LoanAppMakerCheckerDisburseService,
  LoanAppEntryFeesService,
  LoanApplicationListService,
  LoanApplicationCreateService,
  LoanApplicationUpdateService,
  LoanApplicationAttachmentDelService,
  LoanAppAttachmentListService,
  LoanAppScheduleService,
  LoanAppGuarantorsListService,
  LoanAppGuarantorService,
  LoanAppGuarantorCreateService,
  RelationshipListService,
  LoanAppCollateralListService,
  LoanApplicationCommentsService,
  LoanAppCollateralCreateService,
  LoanAppUpdateGuarantorService,
  LoanAppCollateralService,
  LoanAppCollateralUpdateService,
  CCRulesListService,
  LoanListService,
  CCRuleCreateService,
  CCRulesInfoService,
  CCRulesUpdateService,
  LoanAppCCActivityListService,
  LoanInfoService,
  LoanEventsService,
  LoanMakerCheckerRollbackService,
  OtherFeesService,
  VaultListService,
  VaultCreateService,
  VaultUpdateService,
  CurrencyListService,
  BranchCreateService,
  BranchUpdateService,
  BranchFieldsService,
  BranchInfoService,
  BranchListService,
  TillCreateService,
  TillListService,
  TillUpdateService,
  VaultInfoService,
  TillInfoService,
  GlobalPermissionsService,
  LoanScheduleService,
  LoanMakerCheckerRepaymentService,
  OperationCreateService,
  OperationListService,
  AccountingEntriesService,
  AccountMakerCheckerService,
  ChartOfAccountsService,
  CurrentAccountTransactionsService,
  SavingEntriesActions,
  SavingEntriesService,
  TermDepositAccountTransactionsService,
  LoanAppCustomFieldsService,
  SavingProductService,
  SavingProductMakerCheckerService,
  SavingProductListService,
  SavingProductCreateService,
  SavingProductUpdateService,
  TermDepositEntriesActions,
  // ReduxBase Actions
  TillInfoActions,
  TillListActions,
  TillCreateActions,
  TillUpdateActions,
  VaultInfoActions,
  VaultListActions,
  VaultCreateActions,
  VaultUpdateActions,
  ProfileChangeActions,
  OperationListActions,
  BranchUpdateActions,
  BranchInfoActions,
  BranchListActions,
  BranchCreateActions,
  OtherFeesActions,
  BusinessSectorListActions,
  BusinessSectorCreateActions,
  BusinessSectorUpdateActions,
  LoanAppCCActivityListActions,
  TagsListActions,
  TagCreateActions,
  TagUpdateActions,
  ChartOfAccountsActions,
  ChartOfAccountCreateActions,
  ChartOfAccountCreateService,
  ChartOfAccountUpdateActions,
  ChartOfAccountUpdateService,
  BorrowingProductCreateActions,
  BorrowingProductCreateService,
  BorrowingProductInfoActions,
  BorrowingProductInfoService,
  BorrowingProductListService,
  BorrowingProductListActions,
  BorrowingProductUpdateActions,
  BorrowingEventsActions,
  BorrowingProductUpdateService,
  // Error Handling
  // ErrorLogService,
  // LOGGING_ERROR_HANDLER_PROVIDERS,
  // {
  //   provide: LOGGING_ERROR_HANDLER_OPTIONS,
  //   useValue: {
  //     rethrowError: false,
  //     unwrapError: false
  //   }
  // },
  WindowRefService,
  LocationService,
  CommonService,
  SystemSettingsShareService,
  ParseDateFormatService,
  PrintOutService,
  AccountsService,
  TellerListService,
  AccountBalanceService,
  ProfileEventsListService,
  LoanAppGuarantorDeleteService,
  LoanAppCollateralDeleteService,
  LoanAttachmentDelService,
  LoanAttachmentListService,
  BorrowingCreateService,
  BorrowingScheduleService,
  BorrowingService,
  BorrowingListService,
  BorrowingUpdateService,
  BorrowingEventsService,
  CurrentAccountsListService,
  OtherFeeListService,
  OtherFeeCreateService,
  OtherFeeUpdateService,
  LoanApplicationFieldsService,
  SavingCreateService,
  SavingService,
  SavingListService,
  SavingProfileListService,
  SavingUpdateService,
  TermDepositCreateService,
  TermDepositService,
  TermDepositListService,
  TermDepositProfileListService,
  TermDepositUpdateService,
  TermDepositEntriesService,
  TermDepositProductCreateService,
  TermDepositProductInfoService,
  TermDepositProductListService,
  TermDepositProductUpdateService,
  TermDepositProductMakerCheckerService,
  HttpClientHeadersService,
  ExchangeRateService,
  BondCreateService,
  BondService,
  BondListService,
  BondUpdateService,
  BondScheduleService,
  BondEventsService,
  MakerCheckerListService,
  SystemSettingService,
  SystemSettingUpdateService,
  PenaltyCreateService,
  PenaltiesService,
  PenaltyUpdateService,
  TransactionTemplatesCreateService,
  TransactionTemplatesInfoService,
  TransactionTemplatesListService,
  TransactionTemplatesUpdateService,
  PaymentMethodCreateService,
  PaymentMethodListService,
  PaymentMethodUpdateService,
  IntegrationWithBankExportService,
  IntegrationWithBankExportFileListService,
  IntegrationWithBankImportFileListService,
  PaymentGatewayService,
  CreditLineService,
  CreditLineCreateService,
  CreditLineUpdateService
];
