import { Injectable } from '@angular/core';
import { TagsListActions } from './tag-list.actions';
import { Actions, Effect } from '@ngrx/effects';
import { TagsListService } from './tag-list.service';
import { ReduxBaseEffects } from '../../redux-base/redux.base.effects';
import { Observable } from 'rxjs';

@Injectable()
export class TagsListEffects {
  @Effect()
  load_tags$: Observable<any> = ReduxBaseEffects.getConfig(this.actions$, this.tagsListActions, () => {
    return this.tagsListService.getTagList();
  });

  constructor(private tagsListService: TagsListService,
              private tagsListActions: TagsListActions,
              private actions$: Actions) {
  }
}
