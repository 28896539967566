import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { CbsTagsSelectorComponent } from "./cbs-tags-selector.component";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
    imports: [CommonModule , FormsModule , BrowserModule , TranslateModule],
  exports: [CbsTagsSelectorComponent],
  declarations: [CbsTagsSelectorComponent]
  })
  
  export class CbsTagsSelectorModule {
  }