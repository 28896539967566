import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService  } from '@ngx-translate/core';
import * as fromRoot from '../../../../../core/core.reducer';

import {
  ITagList ,
  TagsListActions,
  ICreateTag,
  TagCreateActions,
  IUpdateTag,
  TagUpdateActions
} from '../../../../../core/store/tags';
import { environment } from '../../../../../../environments/environment';
import { CustomFormModalComponent } from '../../../../../shared/components/cbs-custom-modal-form/custom-modal-form.component';


@Component({
  selector: 'cbs-tags',
  templateUrl: 'tags.component.html',
  styleUrls: ['./tags.component.css']
})
export class TagsListComponent implements OnInit, OnDestroy {
  @ViewChild(CustomFormModalComponent, {static: false}) private tagFormModal: CustomFormModalComponent;

  
  public newtagFields = [
    {
      id: -1,
      caption: 'NAME',
      extra: null,
      fieldType: 'TEXT',
      name: 'name',
      order: 1,
      required: true,
      unique: false,
      value: '',
    },{
      id: 50,
      caption: 'SELECT COLOR',
      extra: null,
      fieldType: 'COLOR',
      name: 'p',
      order: 2,
      required: true,
      unique: false,
      value: 'DEFAULT_TAG_COLOR',
    }
  ];
  public isNew: boolean;
  public tags: Observable<any>;
  public tagCreateState: ICreateTag;
  public tagUpdateState: IUpdateTag;
  public svgData = {
    collection: 'custom',
    class: 'custom57',
    name: 'custom57'
  };
  public breadcrumbLinks = [
    {
      name: 'CONFIGURATION',
      link: '/configuration'
    },
    {
      name: 'TAGS',
      link: '/configuration/tags'
    }
  ];

  private createTagSub: any;
  private updateTagSub: any;


  constructor(private tagListStore$: Store<ITagList>,
              private tagListActions: TagsListActions,
              private tagCreateStore$: Store<ICreateTag>,
              private tagCreateActions: TagCreateActions,
              private tagUpdateStore$: Store<IUpdateTag>,
              private tagUpdateActions: TagUpdateActions,
              private store$: Store<fromRoot.State>,
              private toastrService: ToastrService,
              private translate: TranslateService) {
    this.tags = this.store$.select(fromRoot.getTagListState);
  }

  ngOnInit() {
    this.loadTags();
    this.createTagSub = this.tagCreateStore$.select(fromRoot.getTagCreateState)
    .subscribe((state: ICreateTag) => {
      if (state.loaded && state.success && !state.error) {
        this.translate.get('CREATE_SUCCESS').subscribe((res: string) => {
          this.toastrService.success(res, '', environment.SUCCESS_TOAST_CONFIG);
        });
        this.closeModal();
        this.loadTags();
        this.resetState('create');
      } else if (state.loaded && !state.success && state.error) {
        this.translate.get('CREATE_ERROR').subscribe((res: string) => {
          this.toastrService.error(state.errorMessage, res, environment.ERROR_TOAST_CONFIG);
        });
        this.tagFormModal.disableSubmitBtn(false);
        this.resetState('create');
      }

      this.tagCreateState = state;
    });

    this.updateTagSub = this.tagUpdateStore$.select(fromRoot.getTagIpdateState)
    .subscribe((state: IUpdateTag) => {
      if (state.loaded && state.success && !state.error) {
        this.translate.get('UPDATE_SUCCESS').subscribe((res: string) => {
          this.toastrService.success(res, '', environment.SUCCESS_TOAST_CONFIG);
        });
        this.closeModal();
        this.loadTags();
        this.resetState('update');
      } else if (state.loaded && !state.success && state.error) {
        this.translate.get('UPDATE_ERROR').subscribe((res: string) => {
          this.toastrService.error(state.errorMessage, res, environment.ERROR_TOAST_CONFIG);
        });
        this.tagFormModal.disableSubmitBtn(false);
        this.resetState('update');
      }
      this.tagUpdateState = state;
    });
  }

  ngOnDestroy() {
    this.createTagSub.unsubscribe();
    this.updateTagSub.unsubscribe();
  }

  loadTags() {
    this.tagListStore$.dispatch(this.tagListActions.fireInitialAction());
  }

  translateCaption(fields) {
    return fields.map(field => {
      const newFieldObj = Object.assign({}, field);
      this.translate.get(field.caption).subscribe((res: string) => {
        newFieldObj.caption = res;
      });
      return newFieldObj;
    });
  }

  openCreateModal(parent?: { parentId, parentName }) {
    this.isNew = true;
    let newTranslatedFields = [];
    if (parent) {
      const parentField = {
        id: parent.parentId,
        caption: 'BELONGS_TO',
        extra: null,
        fieldType: 'TEXT',
        name: 'parentName',
        order: 1,
        required: true,
        unique: false,
        readOnly: true,
        value: parent.parentName
      };
      newTranslatedFields = this.translateCaption([...this.newtagFields, parentField]);
      this.tagFormModal.openModal(newTranslatedFields);
    } else {
      newTranslatedFields = this.translateCaption(this.newtagFields);
      this.tagFormModal.openModal(newTranslatedFields);
    }
  }

  openEditModal(details: { children, data, parent , color}) {
    this.isNew = false;
    const field = {
      id: details.data.id,
      caption: 'NAME',
      extra: null,
      fieldType: 'TEXT',
      name: 'name',
      order: 1,
      required: true,
      unique: false,
      value: details.data.name,
    };
    const colorField ={
        id: 50,
        caption: 'SELECT COLOR',
        extra: null,
        fieldType: 'COLOR',
        name: 'p',
        order: 2,
        required: true,
        unique: false,
        value: '#'+details.data.color,
    }

    const fields = [field , colorField];

    if (details.parent) {
      const parentField = {
        id: details.parent.id,
        caption: 'BELONGS_TO',
        extra: null,
        fieldType: 'TEXT',
        name: 'parentName',
        order: 1,
        required: false,
        unique: false,
        readOnly: true,
        value: details.parent.name,

      };
      fields.push(parentField);
    }
    const newTranslatedFields = this.translateCaption(fields);
    this.tagFormModal.openModal(newTranslatedFields);
  }

  saveNewTag(data) {
    const tagName = data.fields[0];
    const tagColor = data.fields[1];
    const colorvalue = tagColor.value.replace('#' , '');
    const tagParent = data.fields[2];

    if (tagParent) {
      this.tagCreateStore$.dispatch(this.tagCreateActions.fireInitialAction({
        name: tagName.value,
        parentId: tagParent.fieldId,
        color:colorvalue
      }));
    } else {
      this.tagCreateStore$.dispatch(this.tagCreateActions
      .fireInitialAction({name: tagName.value , color:colorvalue}));
    }
  }

  saveEditTag(data) {
    const tagName = data.fields[0];
    const tagColor = data.fields[1];
    const colorvalue = tagColor.value.replace('#' , '');

    const tagEditData = {name: tagName.value,color:colorvalue};

    if (data.fields.length === 3) {
      const parentId = data.fields[2]['fieldId'];
      if (parentId) {
        tagEditData['parentId'] = parentId;
      }
    }
    this.tagUpdateStore$.dispatch(this.tagUpdateActions
    .fireInitialAction(
      {tagEditData: tagEditData,
         id: tagName.fieldId,
         color:colorvalue
      
      }));
  }
  // handleChangeComplete(event ){
  //   this.color = event.color.hex;
  //   this.color = this.color.substring(1);
  // }
  // hideShowColorPicker(id){
  //   const currentElement =  document.getElementById(id);

  //   const elements =  document.getElementsByClassName("c-picker");
  //   if(elements)
  //   for (let i = 0; i <elements.length; i++) {
  //       if(elements[i].classList.contains('opened') 
  //       && elements[i].id != id){
  //     elements[i].classList.replace('opened' , 'closed');
  //   }
    
  // }
  // if(currentElement.classList.contains('opened')) {
  //       currentElement.classList.replace('opened' , 'closed');
  //     } else if(currentElement.classList.contains('closed')){
  //       currentElement.classList.replace('closed' , 'opened');
      
  //   }
  // }

  submitTag(data) {
    if (this.isNew) {
      this.saveNewTag(data);
    } else {
      this.saveEditTag(data);
    }
  }

  closeModal() {
    this.tagFormModal.cancel();
  }

  resetState(state?: string) {
    if (state === 'create') {
      this.tagCreateStore$.dispatch(this.tagCreateActions.fireResetAction());
    } else if (state === 'update') {
      this.tagUpdateStore$.dispatch(this.tagUpdateActions.fireResetAction());
    }
  }

}
