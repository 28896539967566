import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { TagUpdateActions } from './tag-update.actions';
import { TagUpdateService } from './tag.service';
import { ReduxBaseEffects } from '../../redux-base/redux.base.effects';

@Injectable()
export class TagUpdateEffects {

  @Effect()
  update_tag$ = ReduxBaseEffects.getConfig(this.actions$, this.tagUpdateActions, (action) => {
    return this.tagUpdateService.updateTag(action.payload.data.tagEditData, action.payload.data.id,action.payload.data.color);
  });

  constructor(private tagUpdateService: TagUpdateService,
              private tagUpdateActions: TagUpdateActions,
              private actions$: Actions) {
  }
}
