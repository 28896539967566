import { delay } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class TagCreateService {

  constructor(private httpClient: HttpClient) {
  }

  createTag(tag ) {
    return this.httpClient.post(
      `${environment.API_ENDPOINT}tags`,
      JSON.stringify(tag)

      ).pipe(delay(environment.RESPONSE_DELAY));
  }
}
