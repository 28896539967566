import {
  AuthEffects,
  BusinessSectorListEffects,
  BusinessSectorCreateEffects,
  BusinessSectorUpdateEffects,
  TagsListEffects,
  TagCreateEffects,
  TagUpdateEffects,
  CurrentUserEffects,
  ProfileStateEffects,
  ProfilesListEffects,
  ProfileCreateEffects,
  ProfileFieldsEffects,
  ProfileUpdateEffects,
  ProfileChangesEffects,
  ProfileMakerCheckerEffects,
  RoleListEffect,
  UserListEffect,
  UserEffect,
  UserMakerCheckerEffect,
  UserUpdateEffects,
  UserCreateEffects,
  RoleUpdateEffects,
  RoleCreateEffects,
  LocationListEffects,
  LocationCreateEffects,
  LocationUpdateEffects,
  LoanProductEffects,
  LoanProductMakerCheckerEffects,
  LoanPurposeListEffects,
  LoanPurposeCreateEffects,
  LoanPurposeUpdateEffects,
  LoanApplicationAttachmentDeleteEffects,
  LoanAppAttachmentListEffects,
  LoanPayeeEffects,
  PayeeListEffects,
  PayeeCreateEffects,
  PayeeUpdateEffects,
  HolidayListEffects,
  HolidayCreateEffects,
  HolidayUpdateEffects,
  ProfessionListEffects,
  ProfessionCreateEffects,
  ProfessionUpdateEffects,
  ProfileAttachmentListEffects,
  ProfileAttachmentDeleteEffects,
  CollateralTypeCreateEffects,
  CollateralTypeListEffects,
  CollateralTypeUpdateEffects,
  CollateralTypeDetailsEffects,
  EntryFeeListEffects,
  EntryFeeCreateEffects,
  EntryFeeUpdateEffects,
  LoanApplicationEffects,
  LoanAppMakerCheckerDisburseEffects,
  LoanApplicationListEffects,
  LoanApplicationCreateEffects,
  LoanApplicationUpdateEffects,
  LoanPayeeUpdateEffects,
  LoanProductUpdateEffects,
  LoanProductCreateEffects,
  LoanProductHistoryEffects,
  LoanProductListEffects,
  LoanAppScheduleEffects,
  LoanAppGuarantorsListEffects,
  LoanAppGuarantorEffects,
  LoanAppCreateGuarantorEffects,
  LoanAppUpdateGuarantorEffects,
  RelationshipListEffects,
  LoanAppCollateralListEffects,
  LoanApplicationCommentsEffects,
  LoanAppCreateCollateralEffects,
  LoanAppCollateralEffects,
  LoanAppCollateralUpdateEffects,
  CCRulesListEffects,
  CCRuleCreateEffects,
  CCRulesInfoEffects,
  CCRulesUpdateEffects,
  LoanAppCCActivityListEffects,
  LoanListEffects,
  LoanInfoEffects,
  LoanEventsEffects,
  LoanMakerCheckerRollbackEffects,
  OtherFeesEffects,
  VaultListEffects,
  VaultCreateEffects,
  VaultUpdateEffects,
  CurrencyListEffects,
  BranchCreateEffects,
  BranchUpdateEffects,
  BranchInfoEffects,
  BranchListEffects,
  BranchFieldsEffects,
  TillCreateEffects,
  TillListEffects,
  TillUpdateEffects,
  VaultInfoEffects,
  TillInfoEffects,
  GlobalPermissionsEffect,
  RoleEffects,
  RoleMakerCheckerEffects,
  LoanScheduleEffects,
  LoanMakerCheckerRepaymentEffects,
  TellerListEffect,
  OperationCreateEffects,
  OperationListEffects,
  AccountingEntriesEffects,
  AccountMakerCheckerEffects,
  ChartOfAccountsEffects,
  ProfileEventsEffect,
  CurrentAccountTransactionsEffects,
  ExchangeRateEffects,
  SavingProductEffects,
  SavingProductMakerCheckerEffects,
  SavingProductListEffect,
  SavingProductCreateEffects,
  SavingProductUpdateEffects,
  LoanAppGuarantorDeleteEffects,
  LoanAppCollateralDeleteEffects,
  LoanAttachmentDeleteEffects,
  LoanAttachmentListEffects,
  ChartOfAccountCreateEffects,
  ChartOfAccountUpdateEffects,
  BorrowingProductCreateEffects,
  BorrowingProductInfoEffects,
  BorrowingProductListEffects,
  BorrowingProductUpdateEffects,
  BorrowingCreateEffect,
  BorrowingScheduleEffects,
  BorrowingEffects,
  BorrowingListEffects,
  BorrowingUpdateEffects,
  BorrowingEventsEffects,
  CurrentAccountsListEffects,
  OtherFeeEffects,
  OtherFeeListCreateEffects,
  OtherFeeUpdateEffects,
  LoanApplicationFieldsEffects,
  SavingCreateEffect,
  SavingEffects,
  SavingListEffects,
  SavingUpdateEffects,
  SavingProfileListEffects,
  SavingEntriesEffects,
  TermDepositCreateEffect,
  TermDepositEffects,
  TermDepositListEffects,
  TermDepositProfileListEffects,
  TermDepositEntriesEffects,
  TermDepositUpdateEffects,
  TermDepositAccountTransactionsEffects,
  TermDepositProductCreateEffects,
  TermDepositProductInfoEffects,
  TermDepositProductListEffects,
  TermDepositProductUpdateEffects,
  TermDepositProductMakerCheckerEffects,
  DayClosureEffects,
  BondCreateEffect,
  BondEffects,
  BondListEffects,
  BondProductEffects,
  BondUpdateEffects,
  BondScheduleEffects,
  BondEventsEffects,
  MakerCheckerListEffects,
  SystemSettingEffect,
  SystemSettingUpdateEffects,
  LoanPayeeEventsEffects,
  AuditTrailEffects,
  PenaltyCreateEffects,
  PenaltiesEffects,
  PenaltyUpdateEffects,
  TransactionTemplatesEffects,
  TransactionTemplatesCreateEffects,
  TransactionTemplatesListEffect,
  TransactionTemplatesUpdateEffects,
  PaymentMethodCreateEffects,
  PaymentMethodListEffects,
  PaymentMethodUpdateEffects,
  IntegrationWithBankExportEffects,
  IntegrationWithBankExportFileListEffects,
  IntegrationWithBankImportFileListEffects,
  PaymentGatewayEffects,
  CreditLineEffects,
  CreditLineCreateEffects,
  CreditLineUpdateEffects
} from './store';

export const coreEffects: any[] = [
  AuthEffects,
  BusinessSectorListEffects,
  BusinessSectorCreateEffects,
  BusinessSectorUpdateEffects,
  TagsListEffects,
  TagCreateEffects,
  TagUpdateEffects,
  CurrentUserEffects,
  ProfileStateEffects,
  ProfilesListEffects,
  ProfileCreateEffects,
  ProfileFieldsEffects,
  ProfileUpdateEffects,
  ProfileChangesEffects,
  ProfileMakerCheckerEffects,
  RoleListEffect,
  UserListEffect,
  UserEffect,
  UserMakerCheckerEffect,
  UserUpdateEffects,
  UserCreateEffects,
  RoleUpdateEffects,
  RoleCreateEffects,
  LoanProductUpdateEffects,
  LoanProductCreateEffects,
  LoanProductHistoryEffects,
  LoanProductListEffects,
  LoanProductEffects,
  LoanProductMakerCheckerEffects,
  LocationListEffects,
  LocationCreateEffects,
  LocationUpdateEffects,
  LoanPurposeListEffects,
  LoanPurposeCreateEffects,
  LoanPurposeUpdateEffects,
  LoanApplicationAttachmentDeleteEffects,
  LoanAppAttachmentListEffects,
  LoanAppGuarantorsListEffects,
  LoanPayeeUpdateEffects,
  LoanPayeeEffects,
  LoanPayeeEventsEffects,
  PayeeListEffects,
  PayeeCreateEffects,
  PayeeUpdateEffects,
  HolidayListEffects,
  HolidayCreateEffects,
  HolidayUpdateEffects,
  ProfessionListEffects,
  ProfessionCreateEffects,
  ProfessionUpdateEffects,
  ProfileAttachmentListEffects,
  ProfileAttachmentDeleteEffects,
  CollateralTypeListEffects,
  CollateralTypeCreateEffects,
  CollateralTypeUpdateEffects,
  CollateralTypeDetailsEffects,
  EntryFeeListEffects,
  EntryFeeCreateEffects,
  EntryFeeUpdateEffects,
  LoanApplicationEffects,
  LoanAppMakerCheckerDisburseEffects,
  LoanApplicationListEffects,
  LoanApplicationCreateEffects,
  LoanApplicationUpdateEffects,
  LoanAppScheduleEffects,
  LoanAppGuarantorEffects,
  LoanAppCreateGuarantorEffects,
  LoanAppUpdateGuarantorEffects,
  RelationshipListEffects,
  LoanAppCollateralListEffects,
  LoanApplicationCommentsEffects,
  LoanAppCreateCollateralEffects,
  LoanAppCollateralEffects,
  LoanAppCollateralUpdateEffects,
  CCRulesListEffects,
  CCRuleCreateEffects,
  CCRulesInfoEffects,
  CCRulesUpdateEffects,
  LoanAppCCActivityListEffects,
  LoanListEffects,
  LoanInfoEffects,
  LoanEventsEffects,
  LoanMakerCheckerRollbackEffects,
  OtherFeesEffects,
  VaultListEffects,
  VaultCreateEffects,
  VaultUpdateEffects,
  CurrencyListEffects,
  BranchCreateEffects,
  BranchUpdateEffects,
  BranchInfoEffects,
  BranchListEffects,
  BranchFieldsEffects,
  TillCreateEffects,
  TillListEffects,
  TillUpdateEffects,
  VaultInfoEffects,
  TillInfoEffects,
  GlobalPermissionsEffect,
  RoleEffects,
  RoleMakerCheckerEffects,
  LoanScheduleEffects,
  LoanMakerCheckerRepaymentEffects,
  TellerListEffect,
  OperationCreateEffects,
  OperationListEffects,
  AccountingEntriesEffects,
  AccountMakerCheckerEffects,
  ChartOfAccountsEffects,
  CurrentAccountTransactionsEffects,
  SavingEntriesEffects,
  LoanAttachmentListEffects,
  LoanAttachmentDeleteEffects,
  LoanAppCollateralDeleteEffects,
  LoanAppGuarantorDeleteEffects,
  ChartOfAccountCreateEffects,
  ChartOfAccountUpdateEffects,
  ProfileEventsEffect,
  BorrowingProductCreateEffects,
  BorrowingProductInfoEffects,
  BorrowingProductListEffects,
  BorrowingProductUpdateEffects,
  BorrowingCreateEffect,
  BorrowingScheduleEffects,
  BorrowingEffects,
  BorrowingListEffects,
  BorrowingUpdateEffects,
  BorrowingEventsEffects,
  CurrentAccountsListEffects,
  OtherFeeEffects,
  OtherFeeListCreateEffects,
  OtherFeeUpdateEffects,
  LoanApplicationFieldsEffects,
  SavingCreateEffect,
  SavingEffects,
  SavingListEffects,
  SavingProfileListEffects,
  SavingUpdateEffects,
  SavingProductEffects,
  SavingProductMakerCheckerEffects,
  SavingProductListEffect,
  SavingProductCreateEffects,
  SavingProductUpdateEffects,
  TermDepositCreateEffect,
  TermDepositEffects,
  TermDepositListEffects,
  TermDepositProfileListEffects,
  TermDepositUpdateEffects,
  TermDepositEntriesEffects,
  TermDepositAccountTransactionsEffects,
  TermDepositProductCreateEffects,
  TermDepositProductInfoEffects,
  TermDepositProductListEffects,
  TermDepositProductUpdateEffects,
  TermDepositProductMakerCheckerEffects,
  DayClosureEffects,
  ExchangeRateEffects,
  BondCreateEffect,
  BondEffects,
  BondListEffects,
  BondProductEffects,
  BondUpdateEffects,
  BondScheduleEffects,
  BondEventsEffects,
  MakerCheckerListEffects,
  SystemSettingEffect,
  SystemSettingUpdateEffects,
  AuditTrailEffects,
  PenaltyCreateEffects,
  PenaltiesEffects,
  PenaltyUpdateEffects,
  TransactionTemplatesEffects,
  TransactionTemplatesCreateEffects,
  TransactionTemplatesListEffect,
  TransactionTemplatesUpdateEffects,
  PaymentMethodCreateEffects,
  PaymentMethodListEffects,
  PaymentMethodUpdateEffects,
  IntegrationWithBankExportEffects,
  IntegrationWithBankExportFileListEffects,
  IntegrationWithBankImportFileListEffects,
  PaymentGatewayEffects,
  CreditLineEffects,
  CreditLineCreateEffects,
  CreditLineUpdateEffects
];
