import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';

import { TagCreateActions } from './tag-create.actions';
import { TagCreateService } from './tag-create.service';
import { ReduxBaseEffects } from '../../redux-base/redux.base.effects';

@Injectable()
export class TagCreateEffects {

  @Effect()
  create_tag$ = ReduxBaseEffects.getConfig(this.actions$, this.tagCreateActions, (action) => {
    return this.tagCreateService.createTag(action.payload.data );
  });
  

  constructor(private tagCreateService: TagCreateService,
              private tagCreateActions: TagCreateActions,
              private actions$: Actions) {
  }
}
