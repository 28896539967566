import { ReduxBaseActions } from '../../redux-base';

export class TagUpdateActions extends ReduxBaseActions {
  getInitialActionCommandName(): string {
    return 'UPDATE_ACTION';
  }

  getClassName(): string {
    return 'TAG_UPDATE';
  }
}
