import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../../../../core/core.module';
import { TranslateModule } from '@ngx-translate/core';

import { TagsRoutingModule } from './tags-routing.module';
import { TagsListComponent } from './tags/tags.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    TranslateModule,
    TagsRoutingModule
  ],
  declarations: [
    TagsListComponent
  ]
})
export class TagsModule {
}
