import { ReduxBaseActions } from '../../redux-base';

export class TagsListActions extends ReduxBaseActions {
  getInitialActionCommandName(): string {
    return 'LIST_ACTION';
  }

  getClassName(): string {
    return 'TAG_LIST';
  }
}
