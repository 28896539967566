import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild ,  NgModule } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import * as fromStore from '../../../core/store';





@Component({
  selector: 'cbs-tags-selector',
  templateUrl: './cbs-tags-selector.component.html',
  styleUrls: ['./cbs-tags-selector.component.scss']
})
export class CbsTagsSelectorComponent implements OnInit {


  // tempTagList = [];
  @Input() tags;
  

  public tagsSelect;
  public showOptions = false ; 
  public searchTags ;

  public selectedTags = [];
  public filteredTags;

  @Output() ontagsChanges : EventEmitter<any> = new EventEmitter();



  public tagsObservable: Observable<any>;
  
  @ViewChild('searchInput', { static: false }) searchInput: ElementRef;
  @ViewChild('optionsEle', { static: false }) optionsEle: ElementRef;
  constructor(
    private tagListStore$: Store<fromStore.ITagList>,
    private tagListActions: fromStore.TagsListActions,
  ) {

    // this.tagsObservable = this.store$.select(fromRoot.getTagListState);

   }

  ngOnInit() {
    this.filteredTags = [...this.tags]
  }

  toggleDropdown(event) {
    this.showOptions = !this.showOptions;
    event.stopPropagation();
  }
  
  toggleOption(tag) {
  
  
    if (this.isContainId(tag, this.filteredTags)) {
  
      this.selectedTags = [...this.selectedTags.filter(o => o !== tag)];
    } else {
      this.selectedTags = [...this.selectedTags, tag];
    }
  
    this.filteredTags = [...this.tags.filter(o => !this.selectedTags.includes(o))];
    this.ontagsChanges.emit(this.selectedTags)
  
  }
  
  
  removeSelectedOption(option: string) {
    this.selectedTags = this.selectedTags.filter(o => o !== option);
    this.filteredTags = [...this.tags.filter(o => !this.selectedTags.includes(o))];
    this.ontagsChanges.emit(this.selectedTags)
  
  }
  
  onSearchInputChange() {
    this.filteredTags = this.tags.filter(tag =>
      tag.data.name.toLowerCase().includes(this.searchTags.toLowerCase())
      && !this.selectedTags.includes(tag)
    );
  }
  
  
  isContainId(tag, tags) {
    tags.forEach(item => {
      if (item.data.id == tag.data.id) {
        return true;
      }
    })
    return false;
  }


}

