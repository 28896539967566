import { Component, OnInit, OnDestroy, Renderer2, ElementRef, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  ILoanAppState,
  ILoanAppFormState,
  ILoanApplicationComments
} from '../../../core/store/loan-application';
import * as fromRoot from '../../../core/core.reducer';
import * as fromStore from '../../../core/store';
import { LoanAppStatus } from '../../../core/loan-application-status.enum';
import { Observable, Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ILoanInfo } from '../../../core/store';

const SVG_DATA = {
  collection: 'standard',
  class: 'answer-public',
  name: 'answer_public'
};

interface User {
  firstName : string ,
  lastName : string
}

@Component({
  selector: 'cbs-loan-comments',
  templateUrl: 'loan-comments.component.html',
  styleUrls: ['loan-comments.component.scss']
})

export class LoanCommentsComponent implements OnInit, OnDestroy {
  public svgData = SVG_DATA;
  public loanApplicationCommentsData: any;
  public filterdLoanAppCommentsData : any ;  
  public loanAppId: number;
  public breadcrumbLinks = [];
  public text: string;
  public commentText: string;
  public disabledSendButton = true;
  public loan: any;
  public loanStatus: any;
  public tagsObservable : Observable<any> ;
  public tags;
  public commentTags : Number [] ;
  tempTagList = [];

  private loanApplicationCommentSub: Subscription;
  private loanSub: Subscription;
  private loanApplicationSub: Subscription;
  private tagsSub: Subscription;

  
  selectedTags = [];
  filterTags = [];
  filterUsers = [];
  showOptions: boolean = false;
  searchTags: string = '';
  filteredTags;
  searchCommentsText: string = '';
  isSearchExpanded: boolean = true;
  isAscending;
  isFilterExpanded = false;

  //#region filter  
  toggleFilterBar() {
    this.isFilterExpanded = !this.isFilterExpanded;
    this.closeTagDropdown()
  }

  //#region filter tags 


  isTagsDropdownOpen = false;
  multiSelectFilterTags = [];


  checkTagsDropdown(tag): void {
    if (this.multiSelectFilterTags.includes(tag)) {
      this.multiSelectFilterTags = this.multiSelectFilterTags.filter((item) => item !== tag);
      this.onTagsFilter()
    } else {
      this.multiSelectFilterTags.push(tag);
      this.onTagsFilter()
    }

  }

  onTagsFilter() {
    if (this.multiSelectFilterTags.length > 0) {

      this.filterdLoanAppCommentsData = this.loanApplicationCommentsData.loanApplicationComments.filter(comment =>
        this.searchForTagsInsideComments(comment.tags, this.multiSelectFilterTags)
      );
    }
    else {
      this.filterdLoanAppCommentsData = this.loanApplicationCommentsData.loanApplicationComments
    }
  }

  searchForTagsInsideComments(tags, filteredTags) {
    var bol = false;
    if (tags) {
      tags.forEach(tag => {
        filteredTags.forEach(filterTag => {
          if (tag.id == filterTag.data.id) {
            return bol = true;
          }
        })
      })
    }
    return bol;
  }

  closeTagDropdown(): void {
    this.isTagsDropdownOpen = false;
  }

  toggleTagDropdown(): void {
    this.isTagsDropdownOpen = !this.isTagsDropdownOpen;
  }

  //#endregion


  //#region filter search 

  expandSearch() {
    this.isSearchExpanded = true;
  }
  onSearchComments() {
    this.filterdLoanAppCommentsData = this.loanApplicationCommentsData.loanApplicationComments.filter(comment =>
      (comment.payload.toLowerCase().includes(this.searchCommentsText))
    )
  }

  //#endregion


  //#region filter date sort
  toggleSort() {
    this.isAscending = !this.isAscending;
    this.sortItems();
  }
  sortItems() {
    if (this.isAscending) {
      this.filterdLoanAppCommentsData.sort((a: any, b: any) => { return Date.parse(b.createdAt) - Date.parse(a.createdAt) });
      this.loanApplicationCommentsData.loanApplicationComments.sort((a: any, b: any) => { return Date.parse(b.createdAt) - Date.parse(a.createdAt) });
    }
    else {
      this.filterdLoanAppCommentsData.sort((a: any, b: any) => { return Date.parse(a.createdAt) - Date.parse(b.createdAt) });
      this.loanApplicationCommentsData.loanApplicationComments.sort((a: any, b: any) => { return Date.parse(a.createdAt) - Date.parse(b.createdAt) });
    }

  }
  //#endregion


  //#region filter user name
  
  
  usersFilterSelected : User[] = [];
  isUserDropdownOpen = false;


  checkUsersDropdown(user:User): void {
     if (this.usersFilterSelected.includes( user )) {
      this.usersFilterSelected = this.usersFilterSelected.filter((item) =>  user !== item ); 
      this.filterUsersName(user)
    } else {
      this.usersFilterSelected.push(user);
      this.filterUsersName(user)
    } 
  }

  filterUsersName(filterProp  : User ) { 
    var i = 0
    if (this.usersFilterSelected.length > 0) {
      this.filterdLoanAppCommentsData = this.loanApplicationCommentsData.loanApplicationComments.filter(comment =>
        ( JSON.stringify( { firstName : comment.userFirstName ,  lastName : comment.userLastName  }) 
        === JSON.stringify(filterProp))  
             
      );
    }
    else {
      this.filterdLoanAppCommentsData = this.loanApplicationCommentsData.loanApplicationComments
    }
  }
  closeUserDropdown(): void {
    this.isUserDropdownOpen = false;
  }

  toggleUsersDropdown(): void {
    this.isUserDropdownOpen = !this.isUserDropdownOpen; 
  
  }
  
  //#endregion
  
  //#endregion
  
  usersList :User[] = [] ;
  loadUsers( ){
    var i = 0 ;
    if(this.loanApplicationCommentsData
      &&this.loanApplicationCommentsData.loanApplicationComments )
      this.loanApplicationCommentsData.loanApplicationComments.forEach(comment => {
        i++
        if (!comment)
          return
        const user: User = { firstName: comment.userFirstName, lastName: comment.userLastName };
        if(this.usersList.length === 0)
        this.usersList.push(user)

        this.usersList.forEach(element  => {

          if(element.firstName !== user.firstName && element.lastName !== user.lastName ){
          this.usersList.push(user)
        }
      }
        )
      
  });
  }

  @ViewChild('searchInput', { static: false }) searchInput: ElementRef;
  @ViewChild('optionsEle', { static: false }) optionsEle: ElementRef;
  @ViewChild('filterTagsElement', { static: false }) filterTagsElem: ElementRef;
  @ViewChild('filterUserElement', { static: false }) filterUserElement: ElementRef;

  constructor(private loanApplicationStore$: Store<ILoanAppState>,
    private loanApplicationCommentsStore$: Store<ILoanApplicationComments>,
    private loanAppFormStore$: Store<ILoanAppFormState>,
    private tagListStore$:Store< fromStore.ITagList>,
    private tagListActions: fromStore.TagsListActions,
    private tagsStore:Store<fromStore.ITagList>,
              private store$: Store<fromRoot.State>,
              private el: ElementRef,
              private renderer: Renderer2
              ) {
      this.tagsObservable = this.store$.select(fromRoot.getTagListState);
                this.renderer.listen('window', 'click',(e:Event)=>{
                 
      if (this.optionsEle
        && (e.target as HTMLElement).parentNode != this.optionsEle.nativeElement
        && !this.optionsEle.nativeElement.contains((e.target as HTMLElement).parentNode)) {
        this.showOptions = false;
      }
      else if (this.filterTagsElem
        && !this.filterTagsElem.nativeElement.contains(e.target)
      ) {
        this.closeTagDropdown();
      }
      else if (this.filterUserElement
        && !this.filterUserElement.nativeElement.contains(e.target)
      ) {
        this.closeUserDropdown();
      }
    });   

  }

  ngOnInit() {
    this.loadTags();

    this.loanApplicationCommentSub = this.store$.pipe(select(fromRoot.getLoanApplicationCommentsState))
      .subscribe((loanApplicationComment: ILoanApplicationComments) => {
        if ( loanApplicationComment.loaded && loanApplicationComment.success && loanApplicationComment.loanApplicationComments ) {
          this.loanApplicationCommentsData = loanApplicationComment;
          this.filterdLoanAppCommentsData = loanApplicationComment.loanApplicationComments
          this.loadUsers( );  
        }

 
      });

    this.loanSub = this.store$.pipe(select(fromRoot.getLoanInfoState))
      .subscribe((loanState: ILoanInfo) => {
        if ( loanState.success && loanState.loaded && loanState.loan ) {
          this.loan = loanState.loan;
          this.loanStatus = this.loan.status;
          const loanProfile = this.loan.profile;
          const profileType = loanProfile['type'] === 'PERSON' ? 'people'
            : loanProfile['type'] === 'COMPANY' ? 'companies'
              : 'groups';
          const breadcrumbPart = profileType === 'groups' ? 'LOAN APPLICATION ' + this.loan['loanApplicationId'] : this.loan['code'];

          this.breadcrumbLinks = [
            {
              name: loanProfile['name'],
              link: `/profiles/${profileType}/${loanProfile['id']}/info`
            },
            {
              name: 'LOANS',
              link: '/loans'
            },
            {
              name: breadcrumbPart,
              link: ''
            },
            {
              name: 'COMMENTS',
              link: ''
            }
          ];
        }
      });

    this.loanApplicationSub = this.store$.pipe(select(fromRoot.getLoanApplicationState))
      .subscribe((loanAppState: ILoanAppState) => {
          if ( loanAppState.success && loanAppState.loaded && loanAppState.loanApplication ) {
            this.loanAppId = loanAppState.loanApplication['id'];

            this.loanApplicationCommentsStore$.dispatch(new fromStore.LoadLoanApplicationComments({id: this.loanAppId}));
          }
        }
      );
      this.commentTags =[];
      this.usersList = []

  }

  loadTags() {
    
    this.tagListStore$.dispatch(this.tagListActions.fireInitialAction());
    this.tagsSub = this.tagsObservable.subscribe(res=> {
    this.tags = res;
    
    
    if(this.tags && this.tags.data)
    this.extractTagsChileds();
    this.filteredTags = [...this.tags];
  })

  }
  extractTagsChileds(){
    this.tags.data.forEach(tag=>{
      this.tempTagList.push(tag);
      this.getChilds( tag );
    })
    this.tags = this.tempTagList;
    this.tempTagList = [];
    
  }
  
  
  getChilds(child ){
    if (child.children && child.children.length > 0 ){
      child.children.forEach(item=>{
        this.tempTagList.push(item);
          this.getChilds(item);
        })
    }      
  }
  
  toggleDropdown(event) { 
    this.showOptions = !this.showOptions;
    event.stopPropagation(); 
  }

  toggleOption(tag) {


    if (this.isContainId(tag, this.filteredTags)) {

      this.selectedTags = [...this.selectedTags.filter(o => o !== tag)];
    } else {
      this.selectedTags = [...this.selectedTags, tag];
    }

    this.filteredTags = [...this.tags.filter(o => !this.selectedTags.includes(o))];
      this.disabledSendButton = this.checkbuttonValidation();

  }


  removeSelectedOption(option: string) {
    this.selectedTags = this.selectedTags.filter(o => o !== option);
    this.filteredTags = [...this.tags.filter(o => !this.selectedTags.includes(o))];
    this.disabledSendButton = this.checkbuttonValidation();

  }
  
  onSearchInputChange() {
    this.filteredTags = this.tags.filter(tag =>
      tag.data.name.toLowerCase().includes(this.searchTags.toLowerCase()) 
      &&  !this.selectedTags.includes(tag)
    );
  }
  

  isContainId(tag , tags) {
    tags.forEach(item =>{
      if(item.data.id == tag.data.id){
        return true;
      }
    })
    return false;
  }

  valueText(value) {
    this.commentText = value;
    this.disabledSendButton = this.checkbuttonValidation();
  }
  
  checkbuttonValidation() :boolean{
    return (
      !(this.commentText && this.commentText.match(/^\s+$/) === null)
       || this.selectedTags.length < 1 
      //  || this.commentTags.length > this.tags.length                 
       )
  }
  removeAddedTags(){
    this.selectedTags = [];
    this.filteredTags = [...this.tags];

  }

  prepareTagsIds(commentTags){
    this.selectedTags.forEach(item => {
      commentTags.push(item.data.id)

    })
  }
  
  sendComment() {
    if ((this.commentText && this.commentText.match(/^\s+$/) === null)
    && this.selectedTags.length > 0 
    // && this.commentTags.length < this.tags.length                 
     ) {
      let commentTags : number[] =[];
      this.prepareTagsIds(commentTags)
      this.loanApplicationCommentsStore$.dispatch(new fromStore.SetLoanApplicationComments({
        id: this.loanAppId,
        payload: this.commentText,
        tags:commentTags
      }));
  
      setTimeout(() => {
        this.loanApplicationCommentsStore$.dispatch(new fromStore.LoadLoanApplicationComments({id: this.loanAppId}));
        this.commentText = '';
        this.disabledSendButton = true;
        this.removeAddedTags();
        this.usersList = [];
      }, 300);
      this.removeAddedTags();
      this.usersList = [];
      
    }
  }

  onScroll(params) {
    this.loanApplicationCommentsStore$.dispatch(new fromStore.LoadLoanApplicationComments({
      id: this.loanAppId,
      query: params
    }));
  }

  ngOnDestroy() {
    this.loanApplicationCommentSub.unsubscribe();
    this.loanSub.unsubscribe();
    this.loanApplicationSub.unsubscribe();    
    this.tagsSub.unsubscribe();

    
  }
}
